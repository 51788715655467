<template>
    <div class="cadastro">
        <div class="container">
            <div class="col-12 col-xl-10 offset-xl-1">
                <div class="row">
                    <div class="col-12 mt-5 text-center titulo">
                        <h1>Identificação</h1>
                    </div>
                </div>
            </div>
            <div class="col-12 col-xl-10 offset-xl-1 mt-4">
                <div class="row">
                    <div class="col-12 mt-5">
                        <h5>Informação pessoa</h5>
                    </div>
                    <div class="col-12 col-md-5 form-group">
                        <label for="nome">Nome Completo</label>
                        <input
                            type="text"
                            name="nome"
                            class="form-control"
                            id="nome"
                            v-model="cadastro.nome"
                        />
                        <div class="invalid-feedback d-block">
                            {{ erros.nome }}
                        </div>
                    </div>
                    <div class="col-12 col-md-4 form-group">
                        <label for="email">E-mail</label>
                        <input
                            type="email"
                            name="email"
                            class="form-control"
                            id="email"
                            v-model="cadastro.email"
                        />
                        <div class="invalid-feedback d-block">
                            {{ erros.email }}
                        </div>
                    </div>
                    <div class="col-12 col-md-3 form-group">
                        <label for="telefone">Celular</label>
                        <input
                            type="text"
                            name="celular"
                            class="form-control"
                            id="celular"
                            v-model="cadastro.celular"
                            v-maska="'(##) #####-####'"
                        />
                        <div class="invalid-feedback d-block">
                            {{ erros.celular }}
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-12 mt-5">
                        <h5>Dados de entrega</h5>
                    </div>
                    <div class="col-12">
                        <div
                            v-for="item in listaTipoEntrega"
                            :key="item.id"
                            class="form-check form-check-inline form-group ps-4"
                        >
                            <input
                                class="form-check-input"
                                type="radio"
                                name="entregaOptions"
                                :id="item.descricao"
                                :value="item.id"
                                v-model="tipoEntrega"
                            />
                            <label
                                class="form-check-label"
                                :for="item.descricao"
                                >{{ item.descricao }}</label
                            >
                        </div>
                        <div class="invalid-feedback d-block">
                            {{ erros.entrega }}
                        </div>
                    </div>
                </div>
                <div class="row" v-show="tipoEntrega == 2">
                    <div class="col-12 col-md-4 form-group">
                        <label for="cep">CEP</label>
                        <input
                            type="text"
                            name="cep"
                            class="form-control"
                            id="cep"
                            @change="preencherCEP"
                            v-model="entrega.cep"
                            v-maska="'#####-###'"
                        />
                        <div class="invalid-feedback d-block">
                            {{ erros.cep }}
                        </div>
                    </div>
                    <div class="col-12 col-md-8 form-group">
                        <label for="endereco">Endereço</label>
                        <input
                            type="text"
                            name="endereco"
                            class="form-control"
                            id="endereco"
                            v-model="entrega.endereco"
                        />
                        <div class="invalid-feedback d-block">
                            {{ erros.endereco }}
                        </div>
                    </div>
                    <div class="col-12 col-md-4 form-group">
                        <label for="numero">Número</label>
                        <input
                            type="text"
                            name="numero"
                            class="form-control"
                            id="numero"
                            v-model="entrega.numero"
                        />
                        <div class="invalid-feedback d-block">
                            {{ erros.numero }}
                        </div>
                    </div>

                    <div class="col-12 col-md-4 form-group">
                        <label for="complemento">Complemento</label>
                        <input
                            type="text"
                            name="complemento"
                            class="form-control"
                            id="complemento"
                            v-model="entrega.complemento"
                        />
                        <div class="invalid-feedback d-block">
                            {{ erros.complemento }}
                        </div>
                    </div>
                    <div class="col-12 col-md-4 form-group">
                        <label for="bairro">Bairro</label>
                        <input
                            type="text"
                            name="bairro"
                            class="form-control"
                            id="bairro"
                            v-model="entrega.bairro"
                        />
                        <div class="invalid-feedback d-block">
                            {{ erros.bairro }}
                        </div>
                    </div>
                    <div class="col-12 col-md-4 form-group">
                        <label for="cidade">Cidade</label>
                        <input
                            type="text"
                            name="cidade"
                            class="form-control"
                            id="cidade"
                            v-model="entrega.cidade"
                        />
                        <div class="invalid-feedback d-block">
                            {{ erros.cidade }}
                        </div>
                    </div>
                    <div class="col-12 col-md-4 form-group">
                        <label for="uf">Estado</label>

                        <select
                            id="uf"
                            name="uf"
                            class="form-control form-select"
                            v-model="entrega.estado"
                            disabled
                        >
                            <option value="" disabled selected></option>
                            <option value="AC">Acre</option>
                            <option value="AL">Alagoas</option>
                            <option value="AP">Amapá</option>
                            <option value="AM">Amazonas</option>
                            <option value="BA">Bahia</option>
                            <option value="CE">Ceará</option>
                            <option value="DF">Distrito Federal</option>
                            <option value="ES">Espírito Santo</option>
                            <option value="GO">Goiás</option>
                            <option value="MA">Maranhão</option>
                            <option value="MT">Mato Grosso</option>
                            <option value="MS">Mato Grosso do Sul</option>
                            <option value="MG">Minas Gerais</option>
                            <option value="PA">Pará</option>
                            <option value="PB">Paraíba</option>
                            <option value="PR">Paraná</option>
                            <option value="PE">Pernambuco</option>
                            <option value="PI">Piauí</option>
                            <option value="RJ">Rio de Janeiro</option>
                            <option value="RN">Rio Grande do Norte</option>
                            <option value="RS">Rio Grande do Sul</option>
                            <option value="RO">Rondônia</option>
                            <option value="RR">Roraima</option>
                            <option value="SC">Santa Catarina</option>
                            <option value="SP">São Paulo</option>
                            <option value="SE">Sergipe</option>
                            <option value="TO">Tocantins</option>
                        </select>

                        <div class="invalid-feedback d-block">
                            {{ erros.estado }}
                        </div>
                    </div>
                </div>

                <div class="row">
                    <div class="col-12">
                        <div
                            class="
                                box
                                mt-5
                                flex-column flex-md-row
                                d-flex
                                justify-content-between
                            "
                        >
                            <p>Frete: {{ valorFrete | formatarMoedaBR }}</p>
                            <p>
                                Valor Total Pedido:
                                {{ valorTotalPedido | formatarMoedaBR }}
                            </p>
                        </div>
                    </div>
                </div>

                <div class="row">
                    <div class="col-12 mt-5 form-group">
                        <button
                            :disabled="isLoad"
                            type="button"
                            class="btn btn-site mx-auto d-block"
                            @click="finalizar"
                        >
                            Finalizar pedido
                        </button>
                    </div>
                </div>
            </div>
            <loading :active="isLoad" :can-cancel="true" :is-full-page="true" />
        </div>
    </div>
</template>

<script>
import Vue from "vue";
import axiosApi from "@/axios";
import { mapGetters, mapActions } from "vuex";
import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";
import VueSweetalert2 from "vue-sweetalert2";
import "sweetalert2/dist/sweetalert2.min.css";
import _ from "lodash";

Vue.use(VueSweetalert2);

export default {
    components: { Loading },
    data() {
        return {
            listaTipoEntrega: [],
            tipoEntrega: "",

            isLoad: false,

            totalKM: 0,

            regiaoValida: false,

            conflitoCEP: false,

            cadastro: {
                nome: "",
                email: "",
                celular: "",
            },
            entrega: {
                cep: "",
                endereco: "",
                numero: "",
                complemento: "",
                bairro: "",
                cidade: "",
                estado: "",
            },
            erros: [],
        };
    },

    computed: {
        ...mapGetters([
            "valorTotal",
            "valorFrete",
            "obterDadosUsuario",
            "valorTotalPedido",
            "itensCarrinho",
        ]),
    },

    created() {
        axiosApi.get("/pedidos/tipo-entrega").then((response) => {
            this.listaTipoEntrega = response.data.itens;
        });
    },

    watch: {
        tipoEntrega(valor) {
            this.alterarFrete(valor);
            if (valor == 1) {
                this.conflitoCEP = false;
            }
        },

        cadastro: {
            handler() {
                this.salvarDados();
            },
            deep: true,
        },

        entrega: {
            handler() {
                this.salvarDados();
            },
            deep: true,
        },
    },

    mounted() {
        let dados = this.obterDadosUsuario;
        this.tipoEntrega = dados.tipoEntrega;
        this.cadastro = dados.cadastro;
        this.entrega = dados.entrega;
    },

    methods: {
        ...mapActions([
            "adicionarFrete",
            "salvarDadosUsuario",
            "calcularTotalPedido",
            "finalizarPedido",
            "alterarLoading",
            "limparCarrinho",
        ]),

        calcularDistanciaKM(position1, position2) {
            var deg2rad = function (deg) {
                    return deg * (Math.PI / 180);
                },
                R = 6371,
                dLat = deg2rad(position2.lat - position1.lat),
                dLng = deg2rad(position2.lng - position1.lng),
                a =
                    Math.sin(dLat / 2) * Math.sin(dLat / 2) +
                    Math.cos(deg2rad(position1.lat)) *
                        Math.cos(deg2rad(position1.lat)) *
                        Math.sin(dLng / 2) *
                        Math.sin(dLng / 2),
                c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
            var d = R * c;
            return d.toFixed(1);
        },

        async criarQrCodePIX(chave, valor, mensagem, identificador) {
            try {
                let headers = {
                    headers: {
                        "ws-key-api": process.env.VUE_APP_API_WEBSLIM,
                    },
                };

                let dados = {
                    chave: chave,
                    valor: valor,
                    mensagem: mensagem,
                    identificador: identificador,
                };

                let pix = await axiosApi.post(
                    `${process.env.VUE_APP_URL_TOOLS}/pix/qrcode`,
                    dados,
                    headers
                );

                if (pix.data.success) {
                    return pix.data.qrcode;
                } else {
                    return "";
                }
            } catch (error) {
                return "";
            }
        },

        async enviarEmailPedido(pedido) {
            try {
                let headers = {
                    headers: {
                        "bt-key": process.env.VUE_APP_API_WEBSLIM,
                    },
                };

                let email = await axiosApi.post(
                    "/email/pedido",
                    pedido,
                    headers
                );

                return email.data.sucesso;
            } catch (error) {
                return false;
            }
        },

        finalizar() {
            this.limparErros();
            if (this.validarCampos() && this.tipoEntrega > 0) {
                if (this.entrega.estado != "SP" && this.tipoEntrega == 2) {
                    this.$swal(
                        "Opss!",
                        "Não entregamos no momento fora do estado de São Paulo",
                        "warning"
                    )
                } else {
                    if (!this.regiaoValida && this.tipoEntrega == 2){
                        this.$swal(
                                    "Opss!",
                                    "No momento não entregamos para essa região. Desculpe :(",
                                    "warning"
                                )
                    }else{
                        if (parseInt(this.totalKM) > parseInt(process.env.VUE_APP_LIMITE_KM_ENTREGA) && this.tipoEntrega == 2) {
                            this.$swal(
                                "Opss!",
                                `Desculpe, nosso limite de entrega é de no máximo neste momento de ${process.env.VUE_APP_LIMITE_KM_ENTREGA} KM`,
                                "warning"
                            )
                        } else {
                            if (this.conflitoCEP) {
                                this.$swal(
                                    "Opss!",
                                    "Temos um conflito de CEP de destino com o tipo de entrega suportada. Entre em contato com nosso atendimento para maiores informações",
                                    "warning"
                                )
                            } else {
                                this.criarPedido();
                            }
                        }
                    }
                }
            }
        },

        async alterarFrete(valor) {
            if (valor == 1) {
                await this.adicionarFrete(0);
            } else {
                if (this.entrega.cep) {
                    let valor = await this.calcularFrete('moto',5,process.env.VUE_APP_ENDERECO_ORIGEM,this.entrega.cep)
                    await this.adicionarFrete(valor)
                }
            }
            this.salvarDados();
        },

        async calcularFrete(tipo, peso, origem, destino) {
            let dados = {
                tipo: tipo,
                peso: peso,
                valor: this.valorTotalPedido,
                cep_origem: origem,
                cep_destino: destino,
            };

            let calculo = await axiosApi.post('/pedidos/calcular-frete', dados);
            if (calculo.data.sucesso) {
                let cOrigem = {
                    lat: calculo.data.coordenadas.origem.lat,
                    lng: calculo.data.coordenadas.origem.long,
                }

                let cDestino = {
                    lat: calculo.data.coordenadas.destino.lat,
                    lng: calculo.data.coordenadas.destino.long,
                }

                this.totalKM = this.calcularDistanciaKM(cOrigem, cDestino);

                if (parseInt(this.totalKM) >parseInt(process.env.VUE_APP_LIMITE_KM_ENTREGA)){
                    this.adicionarFrete(0)
                }

                if (!calculo.data.endereco.destino.includes(this.entrega.cep)){
                    this.conflitoCEP = true
                } else {
                    this.conflitoCEP = false
                }

                this.regiaoValida = calculo.data.regiao_valida

                return calculo.data.valor
            } else {
                return 0;
            }
        },

        validarEmail(email) {
            var re = /\S+@\S+\.\S+/;
            return re.test(email);
        },

        validarCampos() {
            let totalErros = 0;

            if (this.cadastro.nome == "") {
                this.erros.nome = "Preencha o campo nome";
                totalErros++;
            }

            if (this.cadastro.email == "") {
                this.erros.email = "Preencha o campo e-mail";
                totalErros++;
            }

            if (!this.validarEmail(this.cadastro.email)) {
                this.erros.email = "Insira um e-mail válido";
                totalErros++;
            }

            if (this.cadastro.celular == "") {
                this.erros.celular = "Preencha o campo celular";
                totalErros++;
            }

            if (this.cadastro.celular.length < 15) {
                this.erros.celular = "Insira um celular válido";
                totalErros++;
            }

            if (!this.tipoEntrega > 0) {
                this.erros.entrega = "Selecione o tipo da entrega";
            }

            if (this.tipoEntrega == 2) {
                if (this.entrega.cep == "") {
                    this.erros.cep = "Preencha o campo cep";
                    totalErros++;
                }

                if (this.entrega.endereco == "") {
                    this.erros.endereco = "Preencha o campo endereço";
                    totalErros++;
                }

                if (this.entrega.numero == "") {
                    this.erros.numero = "Preencha o campo número";
                    totalErros++;
                }

                if (this.entrega.bairro == "") {
                    this.erros.bairro = "Preencha o campo bairro";
                    totalErros++;
                }

                if (this.entrega.cidade == "") {
                    this.erros.cidade = "Preencha o campo cidade";
                    totalErros++;
                }

                if (this.entrega.estado == "") {
                    this.erros.estado = "Preencha o campo estado";
                    totalErros++;
                }
            }

            return totalErros == 0;
        },

        async salvarPedido(pedido) {
            try {
                let response = await axiosApi.post("/pedidos", pedido);
                return response;
            } catch (error) {
                return false;
            }
        },

        async criarPedido() {
            let pedido = {};
            let itens = [];

            pedido.cliente = {};
            pedido.cliente.nome = this.cadastro.nome;
            pedido.cliente.email = this.cadastro.email;
            pedido.cliente.celular = this.cadastro.celular;

            if (this.tipoEntrega == 2) {
                pedido.cliente.endereco = {};
                pedido.cliente.endereco.cep = this.entrega.cep;
                pedido.cliente.endereco.logradouro = this.entrega.endereco;
                pedido.cliente.endereco.numero = this.entrega.numero;
                pedido.cliente.endereco.complemento = this.entrega.complemento;
                pedido.cliente.endereco.bairro = this.entrega.bairro;
                pedido.cliente.endereco.cidade = this.entrega.cidade;
                pedido.cliente.endereco.estado = this.entrega.estado;
            }

            pedido.pagamento = {};
            pedido.pagamento.id_tipo_pagamento = 1;

            pedido.entrega = {};
            pedido.entrega.id_tipo_entrega = this.tipoEntrega;
            pedido.entrega.valor_frete = this.valorFrete;

            _.forEach(this.itensCarrinho, function (valor) {
                let item = {};
                item.id = valor.id;
                item.qtde = valor.qtde;
                item.valor = valor.valor;
                itens.push(item);
            });

            pedido.itens = itens;

            try {
                this.isLoad = true;

                let response = await this.salvarPedido(pedido);

                if (response) {
                    this.finalizarPedido(true);
                    let pix = await this.criarQrCodePIX(
                        process.env.VUE_APP_PIX_CHAVE,
                        this.valorTotalPedido,
                        `Referente ao pedido ${response.data.pedido.codigo}`,
                        response.data.pedido.codigo
                    );

                    let pedidoEmail = {
                        nome: this.cadastro.nome,
                        codigo_pedido: response.data.pedido.codigo,
                        valor_total: this.valorTotalPedido,
                        email: this.cadastro.email,
                    };

                    let email = null;

                    email = await this.enviarEmailPedido(pedidoEmail);

                    let dados = {
                        id: response.data.pedido.id,
                        codigo: response.data.pedido.codigo,
                        qrcode: pix,
                        valorTotal: this.valorTotalPedido,
                    };

                    this.$router.push({
                        name: "Pagamento",
                        params: { pedido: dados, statusEmail: email },
                    });
                } else {
                    this.isLoad = false;
                    this.$swal(
                        "Opss!",
                        "Ocorreu um erro ao processar o pedido. Tente novamente",
                        "error"
                    );
                }
            } finally {
                this.isLoad = false;
            }
        },

        salvarDados() {
            let dados = {
                tipoEntrega: this.tipoEntrega,
                cadastro: this.cadastro,
                entrega: this.entrega,
            };
            this.salvarDadosUsuario(dados);
            this.calcularTotalPedido();
        },

        async preencherCEP(){
            if (this.entrega.cep.length == 9){
                try {
                    this.isLoad = true
                    let url = 'https://viacep.com.br/ws/' + this.entrega.cep.replace('-', '') + '/json';
                    let resposta = await axiosApi.get(url)

                    if (!resposta.data.erro) {
                        this.entrega.endereco = resposta.data.logradouro;
                        this.entrega.cidade = resposta.data.localidade;
                        this.entrega.bairro = resposta.data.bairro;
                        this.entrega.estado = resposta.data.uf;
                        this.erros.cep = '';

                        if (this.entrega.estado != "SP") {
                            this.adicionarFrete(0);
                        } else {
                            await this.alterarFrete(this.tipoEntrega);
                        }

                        this.salvarDados();
                    } else {
                        this.erros.cep = 'CEP não encontrado. Por favor, preencha as informações de endereço manualmente.';
                        this.entrega.endereco = '';
                        this.entrega.bairro = '';
                        this.entrega.cidade = '';
                        this.entrega.uf = '';
                        this.adicionarFrete(0);
                    }
                } catch (error) {
                    this.erros.cep = 'Ocorreu um erro ao consultar o CEP';
                    this.adicionarFrete(0)
                } finally{
                    this.isLoad = false
                }
            }
        },

        limparErros() {
            this.erros = {
                nome: "",
                email: "",
                celular: "",
                cep: "",
                endereco: "",
                numero: "",
                complemento: "",
                bairro: "",
                cidade: "",
                estado: "",
            };
        },
    },
};
</script>

<style lang="scss">
@import "@/assets/scss/style.scss";
.cadastro {
    @include background;
    @include form;

    .titulo {
        @include titulo;
    }
    .btn-site {
        @include btn-site;
    }

    h5 {
        font-family: $roboto;
        font-weight: bold;
        font-style: normal;
        font-size: 17px;
        line-height: 17px;
        color: $amarelo;
        background-color: $marrom;
        padding: 14px 24px;
        display: inline-block;
        position: relative;
        border-radius: 0;
    }

    .box {
        background-color: $marrom;
        padding: 24px 15px;

        p {
            margin: 0;
            color: $branco;
            font-family: $roboto;
            font-weight: bolder;
            font-style: normal;
            font-size: 18px;
            line-height: 18px;
        }
    }
}
@media (max-width: 767.98px) {
    .cadastro {
        padding: 50px 0 80px;
        .box {
            p {
                padding: 10px 0;
            }
        }
    }
}
</style>